.walkabout-map p,
.jumbotron p {
    margin: 0;
    padding: 0;
}

.walkabout-map {
    width: 100%;
    height: 80vh;
    margin-bottom: 1em;
}

.no-interaction {
    user-select: none;
    pointer-events: none;
}

.walkabout-overlay {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: max-content;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
}

.walkabout-control {
    top: 2.5em;
    right: 0.5em;
}

.walkabout-list {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    font-size: 0.7em;
    list-style: lower-latin;
    padding: 0;
    overflow: hidden;
}

.walkabout-list ul {
    padding: 0;
    overflow: hidden;
}

.walkabout-list li {
    font-style: italic;
    overflow: hidden;
}

.walkabout-overview .ol-overviewmap-map {
    border: none !important;
    width: 300px !important;
}

.walkabout-overview .ol-overviewmap-box {
    border: 2px solid blue;
}

.walkabout-overview,
.walkabout-overview.ol-uncollapsible {
    bottom: auto !important;
    left: auto !important;
    right: 0 !important;
    top: 0 !important;
}

.walkabout-overview:not(.ol-collapsed) button {
    bottom: auto !important;
    left: auto !important;
    right: 1px !important;
    top: 1px !important;
}

.walkabout-fullscreen {
    top: 5em;
    right: 0.5em;
}

.fullscreen:-webkit-full-screen {
    height: 100%;
    margin: 0;
}

.fullscreen:-ms-fullscreen {
    height: 100%;
}

.fullscreen:fullscreen {
    height: 100%;
}

.fullscreen {
    margin-bottom: 10px;
    width: 100%;
    height: 500px;
}

.walkabout-spinner {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 20%;
    height: 20%;
    z-index: 1000;
}

.walkabout-spinner>img {
    width: 100%;
}

.ol-layers-control {
    right: 0.5em;
    top: 2.5em;
    background-color: rgba(255,255,255,0.6);
}

.ol-layers-control:hover {
    background-color: rgba(255,255,255,0.9);
}

.ol-layers-control label {
    margin-left: 0.25em;
}

.ol-layers-control>div {
    padding: 0.25em;
}

.ol-layers-control>span>*:first-child {
    margin-left: auto;
}